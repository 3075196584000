import { fileAdapter } from '@/adapters/constructor/utils/file';

export default ({ data }) => {
  return {
    text: data.text,
    title: data.title,
    filesText: data.files_text,
    filesTitle: data.files_title,
    showDate: data.files_show_date,
    files: data.files?.map(fileAdapter),
  };
};
